// Ensure that keys are sorted, to reduce merge conflicts
/* eslint sort-keys: "error" */

import type { SupportedFlagTypes } from '@atlassiansox/feature-flag-web-client';

interface FeatureFlagManifestValue {
  createdOn: string;
  defaultValue: SupportedFlagTypes;
  owner: string;
}
const asFeatureFlagsEnum = <T>(ff: {
  [K in keyof T]: FeatureFlagManifestValue;
}) => ff;

/**
 * Feature flags in LaunchDarkly have to be defined here as well.
 * The `owner` field should be your Trello username.
 */
// eslint-disable-next-line @trello/no-module-logic
export const featureFlags = asFeatureFlagsEnum({
  'atlassian-team': {
    createdOn: '2022-11-23',
    defaultValue: false,
    owner: 'rwang3',
  },
  'billing-platform.shop-down-for-maintenance': {
    createdOn: '2023-05-11',
    defaultValue: false,
    owner: 'jmatthews',
  },
  'ecosystem.power-up-views': {
    createdOn: '2021-02-10',
    defaultValue: false,
    owner: 'khanguyen',
  },
  'enterprise.link-to-atlassian-org-v2-web': {
    createdOn: '2022-06-26',
    defaultValue: false,
    owner: 'greznicov',
  },
  'fep.apollo-read-write-metrics': {
    createdOn: '2022-06-22',
    defaultValue: false,
    owner: 'dcilley',
  },
  'fep.apollo_cache_hydrator.metrics': {
    createdOn: '2019-10-11',
    defaultValue: false,
    owner: 'vkozachok',
  },
  'fep.disconnect_active_clients': {
    createdOn: '2020-06-19',
    defaultValue: false,
    owner: 'mfaith',
  },
  'fep.error-handling-rrs': {
    createdOn: '2022-04-04',
    defaultValue: false,
    owner: 'dcilley',
  },
  'fep.log-connection-information': {
    createdOn: '2022-08-15',
    defaultValue: false,
    owner: 'vkozachok',
  },
  'fep.native-current-board-full': {
    createdOn: '2023-10-02',
    defaultValue: 'true',
    owner: 'jcostello2',
  },
  'fep.operation-name-in-url': {
    createdOn: '2021-09-15',
    defaultValue: false,
    owner: 'vkozachok',
  },
  'fep.react-routing': {
    createdOn: '2023-04-03',
    defaultValue: false,
    owner: 'vkozachok',
  },
  'fep.show_somethings_wrong': {
    createdOn: '2020-06-17',
    defaultValue: false,
    owner: 'mfaith',
  },
  'fep.take_trello_offline': {
    createdOn: '2020-09-25',
    defaultValue: false,
    owner: 'mfaith',
  },
  'fep.use-trello-timing-header': {
    createdOn: '2021-11-01',
    defaultValue: false,
    owner: 'dcilley',
  },
  'fep.user-session-tracking': {
    createdOn: '2023-06-20',
    defaultValue: false,
    owner: 'dcilley',
  },
  'force-websocket-ping-failure': {
    createdOn: '2019-11-14',
    defaultValue: false,
    owner: 'vkozachok',
  },
  'goo.browser-storage-controls': {
    createdOn: '2023-12-01',
    defaultValue: false,
    owner: 'elotto',
  },
  'gp.client-subscriptions': {
    createdOn: '2023-07-12',
    defaultValue: false,
    owner: 'jcostello2',
  },
  'phx.member-boards-slim-quickload': {
    createdOn: '2024-09-09',
    defaultValue: false,
    owner: 'dstraus',
  },
  'platform.linking-platform.datasource.show-jlol-basic-filters': {
    createdOn: '2024-09-11',
    defaultValue: false,
    owner: 'bessary',
  },
  'platform.overlay_deprecation_whitelist': {
    createdOn: '2019-01-03',
    defaultValue: false,
    owner: 'mcowan',
  },
  trello_jwm_crossflow_non_ppm_free_single_player: {
    createdOn: '2024-01-10',
    defaultValue: 'not-enrolled',
    owner: 'achung',
  },
  'web.forcefully-reload-to-exact-version': {
    createdOn: '2022-01-18',
    defaultValue: false,
    owner: 'mfaith',
  },
  'web.google-tag-manager': {
    createdOn: '2023-01-03',
    defaultValue: false,
    owner: 'mellis',
  },
  'web.passively-reload-to-minimum-version': {
    createdOn: '2022-05-11',
    defaultValue: false,
    owner: 'kmarshall',
  },
  'workflowers.butler-ent-admin-only-allowlist': {
    createdOn: '2020-10-21',
    defaultValue: false,
    owner: 'afecenko',
  },
  'xf.statsig-feature-gates-refresher_rgroe': {
    createdOn: '2024-04-17',
    defaultValue: false,
    owner: 'gbolloch',
  },
  'xf.statsig-hydrate-shared-state': {
    createdOn: '2024-05-06',
    defaultValue: false,
    owner: 'achung',
  },
});

export type RegisteredFeatureFlagKey = keyof typeof featureFlags;
