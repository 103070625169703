import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export type AutoOpenCrossFlowMemberFragment = (
  { __typename: 'Member' }
  & Pick<Types.Member, 'confirmed'>
);

export const AutoOpenCrossFlowMemberFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AutoOpenCrossFlowMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Member' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'confirmed' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UseAutoOpenCrossFlowMemberFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<
      AutoOpenCrossFlowMemberFragment,
      Apollo.OperationVariables
    >,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UseAutoOpenCrossFlowMemberFragmentResult
  extends Omit<
    Apollo.UseFragmentResult<AutoOpenCrossFlowMemberFragment>,
    'data'
  > {
  data?: AutoOpenCrossFlowMemberFragment;
}

export const useAutoOpenCrossFlowMemberFragment = ({
  from,
  returnPartialData,
  ...options
}: UseAutoOpenCrossFlowMemberFragmentOptions): UseAutoOpenCrossFlowMemberFragmentResult => {
  const result = Apollo.useFragment<AutoOpenCrossFlowMemberFragment>({
    ...options,
    fragment: AutoOpenCrossFlowMemberFragmentDoc,
    fragmentName: 'AutoOpenCrossFlowMember',
    from: {
      __typename: 'Member',
      ...from,
    },
  });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    return {
      ...result,
      data: undefined,
    };
  }

  return { ...result, data: result.data as AutoOpenCrossFlowMemberFragment };
};
